<template>
  <v-container
    fluid
    class="light-blue lighten-5 pb-16"
  >
    <v-card class="pb-16 pa-4">
      <div class="text-h2 primary--text font-weight-bold text-center mb-4">
        Privacy Policy
      </div>
      Blanket, and its related, affiliated and subsidiary companies (jointly “Blanket,” “us,” or “we”) are committed to keeping all of your personal information secure. When you purchase our products or services, you're trusting us with your information. We take this responsibility seriously and work hard to protect your information. <br> <br>

      Why do we collect your personal information?
      Blanket collects personal information to provide or improve our products and services, advertise products or services you might like, comply with legal requirements and for other business reasons. Details about how we use your personal information are described in this Privacy Policy. Personal information is the kind of data that identifies, ties back to or describes you and includes things like name, address, phone number, IP address or other device or internet information, and varies based on what products and services you have with us. Sometimes we collect this personal information directly from you, but we might also collect some information automatically when you are on our websites or use our mobile app. We can also collect personal information from other parties such as consumer reporting agencies or data providers.
      Information We Collect
      During the quoting, application, or claims handling processes you may give us Information such as your:
      Name
      Address
      Phone number
      Email address
      Social Security number
      Driver's license number
      Date of birth
      <br> <br>

      If you give us your email address, Blanket may use it from time to time to notify you of such things as new services, special offers, or to confirm transactions. If you do not wish to be contacted by email for non-policy related communications, you may unsubscribe by following the instructions at the bottom of any Blanket email you receive.
      If you are a policyholder, you can also log in to your account at WEBSITE, click on "Update Email Preferences" on the right side of the screen and choose the level of communication you'd like to receive from us. <br> <br>

      NOTICE OF COLLECTION FOR CALIFORNIA RESIDENTS. We collect the following categories of personal information:
      Contact and identification information, such as a real name, alias, postal address, unique personal identifiers, email address, account username and password, telephone number, insurance policy number, account name, social security number, signature, gender, driver's license number, physical characteristics, consumer status, and other similar identifiers.
      Driving and claims history, including information about any prior accidents or insurance claims.
      Vehicle information, such as a vehicle identification number (VIN), vehicle details, including any customizations, and vehicle photos.
      Financial identifiers, such as a bank account number, credit card number, debit card number, or other financial information.
      Medical information, such as details involving the injuries sustained in a car accident, the treatment for such injuries, as well as health insurance information.
      Commercial information about consumer transactions and experiences with us and others, such as payment history, claims, coverage and vehicle changes.
      Website and app usage and device information, such as search and browsing history, online identifier(s), Internet Protocol address, search history, and information regarding how users interact with our websites, apps, and online advertisements.
      Location data. When a consumer agrees, we may collect information from their device about their physical location so we can provide services like finding a nearby body shop.
      Audio, electronic, visual, or similar information, such as recorded statements, photos of damaged vehicles, videos of accident locations, and calls made to our customer service centers, which may be recorded.
      Professional or employment-related information in the form of business contact information and education or employment history, which we only use as permitted by law.
      Job Applicant, Employee and Contractor information, in addition to information in the above categories, this information may include birth certificates (to validate dependents), passport numbers, access badge photos, background and screening data, health information (such as body temperature), protected class characteristics, and inferences drawn from the information we collect. This information is used in accordance with state and federal laws and is shared with our human-resources service providers. <br> <br>

      We collect each of the above categories from a variety of sources, including:
      Directly from consumers–either online, by mobile app, by phone or in person; antifraud, marketing, analytics, risk-assessment and other service providers; claimants; attorneys; auto dealerships; body shops; medical-field third-parties; our apps and websites; consumer or claimant insurers; police investigations (i.e. accident reports); fraud investigations; and other third-parties. <br> <br>

      We collect each of the above categories of information for a variety of purposes, including:
      For our core business purposes, such as providing quotes for insurance, processing applications (either for insurance or for employment), establishing and servicing policies, claims handling, providing customer support, and furnishing other insurance services. For example, we use information to verify the driving history and information of our applicants; complete billing, payment and reimbursement obligations; and prevent fraud.
      To assess and improve our product and service offerings, including the development of new products and services.
      To perform data analysis (including market and consumer search and analytics and trend analysis).
      To conduct marketing activities and communicate with you regarding products and services offered by Blanket and its partners.
      To have website and mobile app content (including advertising) delivered based on your Blanket browsing history.
      With your consent, to have website and mobile app content delivered based on your location history
      To enable you to interact with us through social media platforms.
      To detect and prevent fraud and other prohibited or illegal activity.
      To maintain and enhance the security and safety of our systems, data, and workplace.
      To hire qualified employees and maintain our workforce.
      To comply with legal obligations and policies.
      To bring and defend claims.
      For other purposes as permitted by law or to which you consent. <br> <br>

      We disclose all of the above categories of personal information to service providers that assist us in providing insurance-related services, which include offering quotes, processing applications (both employment and insurance), and claims handling. If you are involved in an accident with another insured individual, we may have to share your information for the permitted purpose of handling that incident. We may also have to share your information with our reinsurers, excess insurers, insurance-support organizations that help us detect and prevent fraud, state insurance departments and other governmental and law enforcement authorities (where required by law or to protect our legal interests or in cases of suspected fraud or illegal activities), with consumer reporting agencies as permitted by law, and if ordered by subpoena, search warrant or other court order. We may disclose personal information to service providers involved in our marketing, verification, data-processing, risk-assessment, security and antifraud efforts, and as required or permitted by federal or state law. For additional information regarding the categories of personal information we have disclosed and the categories of entities with which we have disclosed such information, please see "The Information We Disclose," above.
      We have not sold consumers' personal information in the 12-month period preceding the Effective Date of this Privacy Policy.
      Please feel free to contact us with questions or concerns regarding our privacy policy and practices by emailing us at  EMAIL or calling us at PHONE NUMBER
      Users From Outside The U.S.
      Blanket provides its Sites and Services for use by U.S. residents, and U.S. law, and not the laws of other countries, govern these Sites and Services. If you are visiting the Sites or any of the Services, your information will be maintained in the U.S. and in other locations where we may process data.
      By default, we disable the submission of personal information to our Sites from users using European Union and United Kingdom IP addresses, regardless of whether the user is an E.U. or U.K. citizen or using a VPN device. If you need access to your account and are using an E.U. or U.K. IP address, please email EMAIL ADDRESS  with (i) a description of the access and/or help you need, and (ii) an express consent to use any personal information in our possession to respond to your request.
      Information From Third Parties
      We may receive Information about you from consumer reporting agencies, which provide us with motor vehicle reports, claim reports, and/or credit information where permitted by law. When you ask for a rate quotation, we may obtain credit information if permitted by applicable state law.
      Our sales and service representatives do not have access to the details of your credit information. Other companies who view your credit report will not see the Blanket inquiry. It will be visible only to you. Our inquiry will not affect your credit score or credit rating. If you commit to purchase a policy with Blanket, we will also confirm your motor vehicle record and claims history. As permitted by law, we may also review your motor vehicle record, claims history, and/or credit information in connection with any renewal.
      Location Services
      Your current location is only collected and used by us if you permit our services to do so. If you allow your location to be collected using a Blanket app, or with a browser, we will use this information to determine your estimated location. We use this information to distinguish your current location and provide location-related services to you. <br> <br>

      Access To And Correction Of Personal Information
      If you are a resident of Arizona, California, Connecticut, Georgia, Illinois, Maine, Minnesota, Montana, Nevada, New Jersey, North Carolina, Oregon, or Virginia, you have the right to request access to and correction of the personal information that we have collected from or about you. To do so, simply contact Blanket’s customer service department. If you'd like to correct information that you provided to us, our representative will make the appropriate adjustments to Blanket's records. If you wish to correct personal information provided to Blanket by a third party (such as a credit rating agency) the representative will provide you with the applicable third party's contact information. Upon the request of residents of other states, we will take reasonable steps to verify the accuracy of the personal information in our records. <br> <br>
      Obtaining insurance quotes <br> <br>

      To offer the best coverage at affordable price, insurance companies evaluate the risks involved. For example, to be able to insure a home, we collect the property’s exact address as well the customer’s name so we can get their insurance history. If you’re an enrolled driver on a Blanket car policy or voluntarily activated location services on our app (“Car User”), your usage of our app and your driving habits (“Driving Data”) will be used to analyze and evaluate your risk profile, which may ultimately be used as one of the determining factors of your premium. To the extent allowed by law, your policy may be declined, cancelled, or non-renewed based solely on your, or other drivers’ Driving Data. We may, in some cases, require personal, identifiable information such as full name, date of birth, driver’s license and social security number. <br> <br>

      The Information We Disclose
      The Information collected about our customers, former customers, applicants, and employees, will only be disclosed as permitted or required by law.
      For our customers and former customers, much of this collected Information is maintained in your policy and/or claims records. We use this Information to process and service your policy; to settle claims; with your consent; or as directed by you.
      Following are some examples of how we may disclose Information:
      We must exchange Information about you with our agents, investigators, appraisers, attorneys, and other persons who are or will become involved in processing your application and servicing your policy or any claims you may make.
      We disclose your Information to persons or organizations as necessary to perform transactions you request or authorize.
      When you are involved in a claim, policy information is provided to adjusters and the businesses that will repair your vehicle.
      We may share Information with persons or organizations that we have determined need the Information to perform a business, professional, or insurance function for us. These include businesses that help us with administrative and marketing functions. If the law in your state permits, we may share Information with financial institutions with which we have a joint-marketing agreement. All of these entities are obligated to keep the Information that we provide to them confidential and to use the Information only for the purpose for which the Information was provided.
      Information may be provided to organizations conducting actuarial research or audits. In this case, you will not be individually identified in any research report. The organization must agree not to redisclose the Information and the Information will be returned to us or destroyed when it is no longer needed.
      We may also share your Information for other permitted purposes, including:
      with another insurance company if you are involved in an accident with their insured
      with our reinsurers
      with insurance-support organizations that detect and prevent fraud
      among the Blanket companies listed below and other subsidiaries of Blanket Corporation to offer you additional products and services
      with service providers to deliver website and mobile app content based on your location, but only after obtaining your consent
      with consumer reporting agencies as permitted by law
      with medical professionals or institutions to verify coverage or conduct operations or services audits
      with state insurance departments or other governmental or law enforcement authorities if required by law or to protect our legal interests or in cases of suspected fraud or illegal activities
      if ordered by a subpoena, search warrant or other court order or mandatory government or law enforcement process
      if necessary to defend our legal rights, or protect our employees or customers
      in the event of a business transaction involving the sale of all of or a portion of our business or assets in which case we will comply with applicable laws regarding notice and consent in such transfers <br> <br>

      Confidentiality and Security
      We restrict access to your Information to employees who we have determined need it to provide products or services to you. We train our employees to safeguard customer information, and we require them to sign confidentiality agreements.
      We maintain a variety of physical, electronic, and procedural safeguards to protect your Information from unauthorized access by third parties.
      Information about our former customers and about individuals who have obtained quotes from us is safeguarded to the same extent as Information about our current policyholders.
      California Residents
      Under the California Consumer Privacy Act ("CCPA"), California residents have the following rights: (1) the right to know, which is the right to request that Blanket disclose information about, or a copy of, the types of personal information we collect, use, and disclose about them; (2) the right to delete, which is the right to request that we delete personal information that is collected or maintained by Blanket; and (3) the right to non-discrimination, which is the right to be free from discrimination for exercising any consumer rights under the CCPA. We may limit our responses to any requests under the CCPA as permitted by law. For example, we need to maintain certain information to provide our services to you or for legal and compliance purposes.
      To exercise your rights to know or delete under the CCPA, please click here or call us toll-free at 877-832-0937. For verification purposes only, we will collect a limited number of personal identifiers, send those through a secure verification system, ask you follow-up questions through our online portal or representative, and then confirm whether your identity has been verified.
      You may also designate an authorized agent to make a request to know or delete on your behalf. To designate an authorized agent, you must provide the natural person, or entity registered with the California Secretary of State, with written permission to submit such a request. You (or your agent) will still be required to verify your identity. However, if an authorized agent has been provided with power of attorney in accordance with the California Probate Code, then no additional written authorization is required. All agents must submit proof to Blanket that they have been authorized by any consumer(s) to act on their behalf.
      External Sites
      The Sites may contain links to third-party websites, including, without limitation, the websites of the insurance carriers offering the third-party insurance products made available to you through the Services (“External Sites”), but we do not endorse, and we are not responsible for the content of any linked External Sites or for the privacy practices of the third parties that own and operate such sites. Before using any External Site, please refer to the terms of use and privacy policies of the External Sites for more information, as those terms and policies govern your use of such External Sites.
      Changes to This Privacy Policy
      We may modify our privacy policy from time to time. The most recent version is always posted at Blanket.com. When we make changes, we will revise the date at the bottom of this Privacy Policy.
      What privacy rights and choices do I have?
      You may have rights to control the use and sharing of your personal information for marketing purposes and may also have the right to view, delete or correct your personal information. These rights differ based on the type of products or services you have with us or by where you live. Residents of certain states like California may have additional rights. <br> <br>

      Contact Us <br> <br>
      If you have additional questions or would like more information regarding our information collection, use of information, or disclosure practices, please feel free to contact us at <a href="mailto:Privacy@blanket.com">Privacy@blanket.com</a>
    </v-card>
  </v-container>
</template>

<script>
  export default {

  }
</script>

<style lang="scss" scoped>

</style>
